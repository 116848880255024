import { countries, states } from "@aerisweather/javascript-sdk/dist/utils/strings";
import { Link as ReachLink } from "gatsby";
import axios from "axios";
import { navigate } from "gatsby";
import _get from "lodash.get";
import { Box, FormField, Heading, Icon, Link, Stack, Text } from "pws-design-system/design-system/";
import React, { useState } from "react";
import { formDataToJson } from "../../../../utils";
import { useMessages } from "../../../common/hooks/useMessages";
import { ThemeContainer } from "../../../common/hooks/useTheme";
import { Form, LoadingButton } from "../common";
import Layout from "../common/Layout";
import { PasswordField } from "./PasswordField";
import ReCAPTCHA from "react-google-recaptcha";
import { isNil } from "lodash";

const errorMap = {
  unknown: {
    message: () =>
      "An unknown error occured. Please try again. If the error persists, please <a href='/support' style='text-decoration: underline'>contact our support team</a>."
  }
};

function RegisterPageDisplay() {
  const recaptchaRef = React.createRef();
  const [recaptchaValue, setRecaptchaValue] = useState(null);

  const { theme } = ThemeContainer.useContainer();
  const [email, setEmail] = useState();
  const fieldStates = states().us;
  const fieldCountries = countries();
  const messages = useMessages();

  function handleSuccess() {
    navigate("/login", {
      state: {
        flash: `Your PWS account has successfully been created, and a confirmation e-mail has been sent to ${email}. You will need to confirm your new account by following the instructions provided in the email after which you can login.`
      }
    });
  }

  function handleChange(event: React.SyntheticEvent) {
    const value = (event.target as HTMLInputElement).value;
    setEmail(value);
  }

  class ValidationError {
    constructor(error: any) {
      this.message = error.message || "Unknown validation error";
      this.paths = error.path;
    }
  }
  class ValidationErrors {
    errors: any[];
    constructor(error: any) {
      const body = _get(error, "error.description.body", null);
      this.errors = body.map(error => new ValidationError(error));
    }
  }

  const handleSubmit = async (
    event,
    setErrors,
    send,
    action,
    withCredentials,
    successHandler,
    errorHandler
  ) => {
    const target = event.target as HTMLFormElement;
    const formData = new FormData(target);
    setErrors(null);
    send("submitting");

    setRecaptchaValue(null);
    recaptchaRef.current?.reset();

    try {
      const response = await axios({
        url: `${process.env.GATSBY_PWS_API_URL}${process.env.GATSBY_REGISTER_URL}`,
        method: "POST",
        withCredentials: true,
        headers: {
          "Content-Type": "application/json"
        },
        data: formDataToJson(formData)
      });
      send("success");
      handleSuccess();
      console.log(response);
    } catch (error) {
      console.log(error);
      const data = _get(error, "response.data", null);
      const code = _get(error, "response.data.error.code");
      if (code === "validation_error") {
        const validationErrors = new ValidationErrors(data);
        setErrors(validationErrors.errors.map(error => error.message).join(","));
      } else if (code === "invalid_recaptcha") {
        setErrors(
          "Captcha verification failed. Refresh the page and try again or contact us directly at support@aerisweather.com."
        );
      } else {
        setErrors(errorMap.unknown.message());
      }
      send("errored");
    }
  };


  function onChange(value) {
    setRecaptchaValue(value);
  }


  return (
    <Layout
      metaTitle={_get(messages, "registration_page_title", "")}
      metaDescription={_get(messages, "registration_page_meta_description", "")}
    >
      <Form
        HeadingSlot={
          <Box mb={4}>
            <Heading as="h1" variant="headline">
              Create an account
            </Heading>
            <Text variant="caption1">
              Already have an account?{" "}
              <Link as={ReachLink} to="/login" fontWeight="bold">
                Log in
              </Link>
              .
            </Text>
          </Box>
        }
        action={`${process.env.GATSBY_PWS_API_URL}${process.env.GATSBY_REGISTER_URL}`}
        successHandler={handleSuccess}
        submitHandler={handleSubmit}
        disabled={isNil(recaptchaValue)}
        SubmitSlot={(isLoading: Boolean, disabled: boolean) => (
          <LoadingButton
            width={["100%", null, null, "auto"]}
            isLoading={isLoading}
            variantColor="brand"
            disabled={disabled}
            type="submit"
            mt={4}
          >
            Sign Up
          </LoadingButton>
        )}
      >
        <Box mb={5}>
          <Stack spacing={4}>
            <Stack spacing={4} isInline>
              <FormField
                name="firstName"
                label="First Name"
                placeholder=""
                field={{
                  type: "text",
                  variant: "flushed",
                  background: theme.colors.bg.base.primary
                }}
                required
              />
              <FormField
                name="lastName"
                label="Last Name"
                placeholder=""
                field={{
                  type: "text",
                  variant: "flushed",
                  background: theme.colors.bg.base.primary
                }}
                required
              />
            </Stack>
            <FormField
              name="username"
              label="Email"
              placeholder=""
              onChange={handleChange}
              field={{
                type: "email",
                variant: "flushed",
                rightElement: <Icon name="email" color="gray.300" />,
                background: theme.colors.bg.base.primary
              }}
              required
            />
            <Stack spacing={4} isInline>
              <Box>
                <PasswordField />
              </Box>
              <FormField
                name="password2"
                label="Verify Password"
                placeholder=""
                field={{
                  type: "password",
                  variant: "flushed",
                  rightElement: <Icon name="lock" color="gray.300" />,
                  background: theme.colors.bg.base.primary
                }}
                required
              />
            </Stack>
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={process.env.GATSBY_RECAPTCHA_V2_CLIENT_KEY}
              onChange={onChange}
            />
          </Stack>
        </Box>
      </Form>
    </Layout>
  );
}

export default function RegisterPage(props) {
  return <RegisterPageDisplay {...props}>{props.children}</RegisterPageDisplay>;
}
