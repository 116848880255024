import React from "react";
import RegisterPage from "../react/pages/authentication/register/";
import { Providers } from "../react/common/layouts/Layout";

export default function RegisterGatsbyEntry() {
  return (
    <Providers>
      <RegisterPage></RegisterPage>
    </Providers>
  );
}
